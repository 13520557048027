import React from "react"
import Layout from "components/Layout/LayoutNewYork"
import PageHeader from "components/shared/PageHeader"
import styled from "styled-components";
import {Container} from 'reactstrap';

const Content = styled(Container)`
    position: relative;
    margin: 0 auto;
    text-align: left;
`

const PrivacyPage = () => (
    <Layout
        title="Privacy Policy | New York | BTTF"
        booking="https://www.telecharge.com/Broadway/Back-to-the-Future-the-Musical/Overview?AID=BWY001390400&utm_source=show_site&utm_campaign=Back-to-the-Future-the-MusicalSS&utm_medium=web"
    >
        <PageHeader city="new-york" title="Privacy Policy" />
        <Content className="my-3 my-md-5">
            <h2>
                <strong>Introduction</strong>
            </h2>
            <p>
                This website, controlled by McFly Productions Limited provides marketing
                information about Back To The Future The Musical. McFly Productions Limited
                is the Data Controller, for the purposes of Data Protection Law, when your
                personal information is processed as a result of your visit to this
                website.
            </p>
            <p>
                We are committed to providing you with relevant information about, and
                control over, how we process your personal information.
            </p>
            <p>
                This policy explains what personal information we may record, why we do so,
                how you may control the use of your information and how we protect your
                information. Details of your legal rights and how to exercise them are also
                set out below.
            </p>
            <p>
                This policy was last updated on 16.05.2019. Any future changes to our
                policy will be reflected here.
            </p>
            <p>
                <strong>
                    <br/>
                    Contacting us
                </strong>
            </p>
            <p>
                Questions, comments and requests regarding this privacy policy are welcomed
                and should be addressed by email to <a href="mailto:info@intheatreproductions.com">info@intheatreproductions.com</a> or by post to Phoebe Fairbrother, McFly Productions Limited, Unit 10, 10-11
                Archer Street, Soho, London, W1D 7AZ.
            </p>
            <p>
                <strong>
                    <br/>
                    What Does This Policy Cover?
                </strong>
            </p>
            <p>
                This Privacy Policy applies only to your use of this website. The website
                may contain links to other websites. Please note that we have no control
                over how your data is collected, stored, or used by other websites and we
                advise you to check the privacy policies of any such websites before
                providing any data to them.
            </p>
            <p>
                <strong>
                    <br/>
                    What Is Personal Data?
                </strong>
            </p>
            <p>
                Personal data is defined by the General Data Protection Regulation (EU
                Regulation 2016/679) (the “GDPR”) and the Data Protection Act 2018
                (collectively, “the Data Protection Legislation”) as ‘any information
                relating to an identifiable person who can be directly or indirectly
                identified in particular by reference to an identifier’.
            </p>
            <p>
                Personal data is, in simpler terms, any information about you that enables
                you to be identified. Personal data covers obvious information such as your
                name and contact details, but it also covers less obvious information such
                as identification numbers, electronic location data, and other online
                identifiers.
                <br/>
                <br/>
            </p>
            <h2>
                <strong>What personal information do we gather</strong>
            </h2>
            <p>
                We collect anonymous technical information including IP address, browser
                type and version, operating system that is used to understand how people
                interact with the website. We will also collect email addresses if you
                provide via the form on the website and consent to the collection.
            </p>
            <h2>
                <strong>Why we process your personal information</strong>
            </h2>
            <p>
                We process your personal information gathered on this website in order to
                tailor our marketing to your needs and to improve our website for future
                customers. The specific purposes for which we may process information, and
                the lawful basis on which we do so in each case, are set out below.
            </p>
            <p>
                <strong>
                    <br/>
                    Tailoring this website to your needs
                </strong>
            </p>
            <p>
                We want to make our website easy to personalise. We use preference cookies
                to store your preferences, such as website language selection, so that you
                do not need to make the same selections every time you visit our website.
                These cookies, stored by your browser on your device, will retain your
                preference choices 30 days. We record your consent to store cookies when
                you first visit the site and click on the cookie notice, and we provide
            details of how to change your settings <a href="http://backtothefuturemusical.com/cookie-policy">here</a>.
            </p>
            <p>
                <strong>
                    <br/>
                    Contacting you with marketing emails to keep you informed about our
                    show(s)
                </strong>
            </p>
            <p>
                We want to help you to hear more about our show. We provide facilities on
                this site for you to sign up to receive email newsletters to learn more
                about the show. We will send such newsletters by e-mail on the basis of
                your specific consent when you supply your email details, which you may
                withdraw at any time by clicking on the unsubscribe link which we will
                include in each email. Your information, and details of when you consented
                to receive marketing emails, will be stored securely in our marketing
                system and will be retained until the production finishes. We will not
                share this data with third parties for their own use, though we may engage
                appropriate suppliers to send emails on our behalf. We use MailChimp to
                handle e-mail signup on this site and to send newsletters. You can find
            their privacy policy  <a href="https://mailchimp.com/legal/privacy/">here</a>.
            </p>
            <p>
                <strong>
                    <br/>
                    Improving the effectiveness of our website
                </strong>
            </p>
            <p>
                We want to make sure that our website enables people to learn about the
                show and to book tickets and that the site meets the needs of our customers
                and is easy to use. To do this, we need to understand how people use this
                site, where they spend time and which pages they find less useful. We use
                Google Analytics to understand how you and other visitors use our website,
                for example recording in what order pages are visited and how long is spent
                on each, so that we may improve the website in future. Google Analytics’
            privacy policy may be found <a href="https://policies.google.com/privacy?hl=en">here</a>. We record
                your consent to store site analytics cookies when you first visit the site
                and click on the cookie notice. The lawful basis on which we process these
                analytics cookies is our legitimate interest in improving our website as we
                believe that the use of such cookies is not intrusive. Details of how to
                change your cookie settings so that you do not participate in our site
            analytics may be found  <a href="http://backtothefuturemusical.com/cookie-policy">here</a>.
            </p>
            <p>
                <strong>
                    <br/>
                    Targeting and measuring the effectiveness of our digital marketing
                </strong>
            </p>
            <p>
                We know that many people visit a show site several times to learn about the
                show and dates when tickets are available before deciding to book tickets.
                We want to make sure that our digital advertisements on other websites and
                social media are seen by people likely to be interested in the show,
                including by visitors to this site who have not yet booked. We also want to
                understand the effectiveness of our advertising in encouraging visits to
                the website and ticket bookings so that we may improve it in future for
                example by reviewing the choice of advertising design and the websites and
                advertising networks used to reach possible customers. Achieving this may
                involve showing different versions of advertisements to different customers
                to measure the responses generated by each. We may use available browsing
                history, including visits to this website, to identify people to whom we
                wish to show advertisements on websites operated by third parties and to
                record how many visitors are referred to our site from other sites. We use
                Sizmek advertising tracking cookies to achieve this and process these
                cookies on the basis of legitimate interest. We believe that showing
                relevant advertisements is in your interest as well as ours and we do not
                believe that such personalisation is generally considered intrusive. We
                respect any decision not to participate in personalised advertising, in
                which case third party sites may show you non-personalised advertising and
                you may still see advertisements for our show. We record your consent to
                store advertising tracking cookies when you first visit the site and click
                on the cookie notice. Details of how to change your settings to opt out of
            personalised advertising may be found  <a href="http://backtothefuturemusical.com/cookie-policy">here</a>. We use
                Sizmek to track our advertising. Details of how long your data is stored
            and how transfers outside the EEA are protected may be found in their  <a href="https://www.sizmek.com/privacy-policy/">Privacy Policy</a>. Our
                tracking cookie information is not shared with third parties for their own
                use, though we may use it to reconcile advertising costs with the
                publishers of third party sites.
            </p>
            <p>
                <strong>
                    <br/>
                    Data Transfers
                </strong>
            </p>
            <p>
                The personal information collected while you use this website may be
                transferred to countries outside the European Economic Area (EEA). This may
                happen, for example, when the computer servers used to host the website or
                our advertising and marketing systems are located in a country outside the
                EEA. If we transfers your personal information outside of the EEA in this
                way, we will take steps to ensure that your privacy rights continue to be
                appropriately protected.
            </p>
            <p>
                <strong>
                    <br/>
                    Do You Share My Personal Data?
                </strong>
            </p>
            <p>
                We will not share any of your personal data with any third parties for
                their own use or for any purposes, subject to the following exceptions.
            </p>
            <p>
                We may engage with third-parties to assist in the provision of activities
                such as quizzes, newsletters and lotteries as described above.
            </p>
            <p>
                Anonymous analytics data for website usage and advertisement effectiveness
                is gathered as described above and is stored by a third-party
            </p>
            <p>
                In some limited circumstances, we may be legally required to share certain
                personal data, which might include yours, if we are involved in legal
                proceedings or complying with legal obligations, a court order, or the
                instructions of a government authority.
            </p>
            <p>
                <strong>
                    <br/>
                    Your rights
                </strong>
            </p>
            <p>
                We want to be transparent about how we process your data and to ensure that
                you can exercise your legal rights:
            </p>
            <p>
                · To be informed – via this Privacy Policy which sets out how we collect
                and process data
            </p>
            <p>
                · To access your data – please contact <a href="mailto:info@intheatreproductions.com">info@intheatreproductions.com</a> if you wish to confirm what data we hold about you
            </p>
            <p>
                · To have your data corrected - please contact <a href="mailto:info@intheatreproductions.com">info@intheatreproductions.com</a> if you feel that data held is incorrect and want this rectified
            </p>
            <p>
                · To have processing of your data ceased - please contact <a href="mailto:info@intheatreproductions.com">info@intheatreproductions.com</a> if you wish us to cease processing your data without erasing it
            </p>
            <p>
                · To have your data erased – please contact <a href="mailto:info@intheatreproductions.com">info@intheatreproductions.com</a> to request deletion of data that we hold about you
            </p>
            <p>
                · To data portability – we process data only to personalise our services to
                you so do not consider it appropriate to pass your data to other
                organisations. Some banks and utilities offer facilities to switch
                accounts, but this is not applicable to our business.
            </p>
            <p>
                · To object to processing of your data on the basis of legitimate interest
                or for statistical purposes – please contact <a href="mailto:info@intheatreproductions.com">info@intheatreproductions.com</a>, use the unsubscribe link in email, amend your cookie settings.
            </p>
            <h2>
                <strong>
                    <br/>
                    Complaints
                </strong>
            </h2>
            <p>
                To exercise all relevant rights, queries of complaints please in the first
                instance contact <a href="mailto:info@intheatreproductions.com">info@intheatreproductions.com</a>
            </p>
            <p>
                You can contact the Information Commissioners Office on 0303 123 1113 or
                via email <a href="https://ico.org.uk/global/contact-us/">https://ico.org.uk/global/contact-us/</a>
                or at the Information Commissioner’s Office, Wycliffe House, Water Lane,
                Wilmslow, Cheshire. SK9 5AF.
            </p>
        </Content>
    </Layout>
)

export default PrivacyPage
